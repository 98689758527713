<template>
	<div style="margin-top: 2%">
		<div style="height:1139px;width:100% ">
			<div class="xm" style="height:1100px;width:100%;">
				<h2 style="margin-top:0;margin-bottom:10px">项目应用</h2>
				<h2 style="margin-top:10px;margin-button:0">Project application</h2>
				<!-- @click="handleClick" -->
				<el-dropdown style="left:65%; top:-63px" split-button type="primary" size="medium" >
					犊牛饲喂管理系统
					<el-dropdown-menu slot="dropdown" >
						<el-dropdown-item>犊牛饲喂管理系统</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<div style="width:100%">
					<img style="width:63%" class="proimg" src="../../assets/1121.png" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ProCenter"
}
</script>

<style scoped>
/* 顶部高度 */
.menu>.el-button {
	border-radius: 0;
	margin: 0;
	border: 0;
	border-left: 1px solid #bdbdbd;
	background-color: #0f91ee;
	color: #fff;
}
.introduction1>div {
	display: inline-block;
	width: 300px;
	/* margin-top: 50PX;
	margin-left: 150px; */
}
.introduction1>div>img{
	padding-top: 50px;
	padding-left: 95px;
}
.introduction1>div>h3{
	color: #717171;
	text-align: center;
	margin-top: 8px;
	
}
.introduction1>div>p{
	color: #717171;
	text-align: center;
	width: 250px;
	margin-left: 20px;
}
.xm>h2{
	margin-left: 19%;
}
.xm {
	position: relative;
}
.el-dropdown + .el-dropdown {
	margin-left: 15px;
	width: 500px !important;
}
.proimg {
	margin-left: 19%;
}
.cooperation>div {
	display: inline-block;
	margin-right: 1%;
	margin-top: 1%;
	margin-bottom: 1%;
	width: 15%;
	height: 50px;
	border: 1px solid #717171;
	text-align: center;
	overflow: hidden;
	font-size: 13px;
}
.cooperation>div>span {
	width: 188px;
	height: 50px;
	text-align: center;
	margin-top: 50px;
	line-height: 44px;
}
.footerkuai>div {
	display: inline-block;
	/* border: 1px solid #fff; */
	color: #4b4f54;
}
.sj1>p {
	display: inline-block;
}
.dhbj>p {
	position: absolute;
	top: 5px;
	color: #494d52;
}
</style>
